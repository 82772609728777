import { isError, reverse } from 'lodash';
import { Urlu2 } from '~/modules/SDK/app/urlu/Urlu2';
import { StrategyCenterRequestError } from '~/pages/strategy_center_admin/_utils/StrategyCenterRequestError';
import { toastRequestFailed } from '~/pages/strategy_center_admin/_utils/toastRequestFailed';
import { toastRequestSuccess } from '~/pages/strategy_center_admin/_utils/toastRequestSuccess';
export class StrategyDevAPI extends Urlu2 {
    /**
     * @example
     *   //
     *   const api = new StrategyDevAPI({
     *     baseUrl: `https://dev.api.strategy-center.futures-op.com`,
     *     middlewares: StrategyDevAPI.middlewares,
     *   })
     */
    static middlewares = 
    // reverse() 使它直接被傳入時，能照順序執行 middlewares
    reverse([
        async function withErrorData(url, init, next) {
            try {
                const response = await next(url, init);
                return response;
            }
            catch (err) {
                return Promise.reject(StrategyCenterRequestError.from(err));
            }
        },
        async function withResponseToast(url, init, next) {
            try {
                const response = await next(url, init);
                if (['post', 'patch', 'delete', 'put'].includes(init.method?.toLowerCase() || '')) {
                    toastRequestSuccess(response);
                }
                return response;
            }
            catch (err) {
                if (isError(err))
                    toastRequestFailed(err);
                return Promise.reject(err);
            }
        },
    ]);
}
